body, html, #root {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  background: #0f2027; /* Dark blue background */
  font-family: 'Roboto', sans-serif;
}

canvas {
  display: block;
}
